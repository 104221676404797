import { IPrivilegeAccess } from '@interfaces/IAuth'
import AllAgency from '@pages/agency/AllAgency'
import CreateAgency from '@pages/agency/CreateAgency'
import CreateAgencyType from '@pages/agency/type/CreateAgencyType'
import EditAgency from '@pages/agency/EditAgency'
import EditAgencyType from '@pages/agency/type/EditAgencyType'
import ViewAgency from '@pages/agency/ViewAgency'
import ViewAgencyType from '@pages/agency/type/ViewAgencyType'
import AllBudgetYear from '@pages/budgetyear/AllBudgetYear'
import CreateBudgetYear from '@pages/budgetyear/CreateBudgetYear'
import EditBudgetYear from '@pages/budgetyear/EditBudgetYear'
import ViewBudgetYear from '@pages/budgetyear/ViewBudgetYear'
import AllChairman from '@pages/chairman/AllChairman'
import CreateChairman from '@pages/chairman/CreateChairman'
import EditChairman from '@pages/chairman/EditChairman'
import ViewChairman from '@pages/chairman/ViewChairman'
import AllCoordinator from '@pages/coordinator/AllCoordinator'
import CreateCoordinator from '@pages/coordinator/CreateCoordinator'
import EditCoordinator from '@pages/coordinator/EditCoordinator'
import ViewCoordinator from '@pages/coordinator/ViewCoordinator'
import DashboardHome from '@pages/dashboard/DashboardHome'
import DashboardMutiple from '@pages/dashboard/DashboardMutiple'
import DashboardSingle from '@pages/dashboard/DashboardSingle'
import AllDownload from '@pages/download/AllDownload'
import Home from '@pages/Home'
import AllLecturer from '@pages/lecturer/AllLecturer'
import CreateLecturer from '@pages/lecturer/CreateLecturer'
import CreateExpertise from '@pages/expertise/CreateExpertise'
import EditExpertise from '@pages/expertise/EditExpertise'
import ViewExpertise from '@pages/expertise/ViewExpertise'
import EditLecturer from '@pages/lecturer/EditLecturer'
import ViewLecturer from '@pages/lecturer/ViewLecturer'
import Login from '@pages/auth/Login'
import Main from '@pages/auth/Main'
import PageNotFound404 from '@pages/PageNotFound404'
import AllMyProject from '@pages/project/AllMyProject'
import AllProject from '@pages/project/AllProject'
import CreateProject from '@pages/project/CreateProject'
import ViewAndEditProject from '@pages/project/ViewAndEditProject'
import AllUser from '@pages/user/AllUser'
import CreateUser from '@pages/user/CreateUser'
import EditUser from '@pages/user/EditUser'
import ViewUser from '@pages/user/ViewUser'
import CreateUserPosition from '@pages/userposition/CreateUserPosition'
import EditUserPosition from '@pages/userposition/EditUserPosition'
import ViewUserPosition from '@pages/userposition/ViewUserPosition'
import AllVendor from '@pages/vendor/AllVendor'
import CreateVendor from '@pages/vendor/CreateVendor'
import CreateVendorType from '@pages/vendor/CreateVendorType'
import EditVendor from '@pages/vendor/EditVendor'
import EditVendorType from '@pages/vendor/EditVendorType'
import ViewVendor from '@pages/vendor/ViewVendor'
import ViewVendorType from '@pages/vendor/ViewVendorType'
import {
    IconBuilding,
    IconBuildingStore,
    IconBulb,
    IconCalendarStats,
    IconCategory2,
    IconFileAnalytics,
    IconFileDownload,
    IconHome,
    IconNews,
    IconUserBolt,
    IconUserPause,
    IconUsers,
    IconUserStar,
} from '@tabler/icons-react'
import EditProfile from '@pages/auth/EditProfile'
import ForgotPassword from '@pages/auth/ForgotPassword'
import AllExpertise from '@pages/expertise/AllExpertise'
import ViewOrganization from '@pages/chairman/organization/ViewOrganization'
import EditOrganization from '@pages/chairman/organization/EditOrganization'
import CreateOrganization from '@pages/chairman/organization/CreateOrganization'
import AllProjectType from '@pages/project/type/AllProjectType'
import CreateProjectType from '@pages/project/type/CreateProjectType'
import ViewProjectType from '@pages/project/type/ViewProjectType'
import EditProjectType from '@pages/project/type/EditProjectType'

interface menu {
    path?: string
    label?: string
    protected?: boolean
    group?: string
    element?: React.ReactNode
    icon?: React.FC<any>
    privilege?: keyof IPrivilegeAccess
}

export const menus: menu[] = [
    {
        path: '/',
        protected: false,
        element: <Home />,
    },
    {
        path: '/login',
        protected: false,
        element: <Login />,
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
    },
    {
        label: 'หน้าหลัก',
        path: '/main',
        protected: true,
        icon: IconHome,
        element: <Main />,
    },
    {
        path: '/profile/edit',
        protected: true,
        element: <EditProfile />,
    },
    {
        label: 'โครงการที่รับผิดชอบ',
        path: '/myprojects',
        protected: true,
        icon: IconNews,
        element: <AllMyProject />,
    },
    {
        label: 'โครงการทั้งหมด',
        path: '/projects',
        protected: true,
        icon: IconNews,
        element: <AllProject />,
        privilege: 'manageProjects',
    },
    {
        path: '/projects/create',
        protected: true,
        element: <CreateProject />,
    },
    {
        path: '/projects/:project_id',
        protected: true,
        element: <ViewAndEditProject />,
    },
    {
        path: '/projects/:project_id/:tab',
        protected: true,
        element: <ViewAndEditProject />,
    },
    {
        label: 'ประเภทกิจกรรมบริการวิชาการ',
        path: '/projects/types',
        protected: true,
        privilege: 'manageProjects',
        element: <AllProjectType />,
        icon: IconCategory2,
    },
    {
        path: '/projects/types/create',
        protected: true,
        element: <CreateProjectType />,
    },
    {
        path: '/projects/types/:project_type_id/view',
        protected: true,
        element: <ViewProjectType />,
    },
    {
        path: '/projects/types/:project_type_id/edit',
        protected: true,
        element: <EditProjectType />,
    },
    {
        label: 'ลูกค้า',
        path: '/agencies',
        protected: true,
        icon: IconBuilding,
        element: <AllAgency defaultTab='agency' />,
    },
    {
        path: '/agencies/types',
        protected: true,
        element: <AllAgency defaultTab='agency_type' />,
    },
    {
        path: '/agencies/create',
        protected: true,
        element: <CreateAgency />,
    },
    {
        path: '/agencies/types/create',
        protected: true,
        element: <CreateAgencyType />,
    },
    {
        path: '/agencies/types/:agency_type_id/edit',
        protected: true,
        element: <EditAgencyType />,
    },
    {
        path: '/agencies/types/:agency_type_id/view',
        protected: true,
        element: <ViewAgencyType />,
    },
    {
        path: '/agencies/:agency_id/edit',
        protected: true,
        element: <EditAgency />,
    },
    {
        path: '/agencies/:agency_id/view',
        protected: true,
        element: <ViewAgency />,
    },
    {
        label: 'ผู้ประสานงาน',
        path: '/coordinators',
        icon: IconUserBolt,
        protected: true,
        element: <AllCoordinator />,
    },
    {
        path: '/coordinators/create',
        protected: true,
        element: <CreateCoordinator />,
    },
    {
        path: '/coordinators/:coordinator_id/edit',
        protected: true,
        element: <EditCoordinator />,
    },
    {
        path: '/coordinators/:coordinator_id/view',
        protected: true,
        element: <ViewCoordinator />,
    },

    {
        label: 'ประธานโครงการ',
        path: '/chairmans',
        protected: true,
        icon: IconUserPause,
        element: <AllChairman defaultTab='chairman' />,
    },
    {
        path: '/chairmans/create',
        protected: true,
        element: <CreateChairman />,
    },
    {
        path: '/chairmans/:chairman_id/edit',
        protected: true,
        element: <EditChairman />,
    },
    {
        path: '/chairmans/:chairman_id/view',
        protected: true,
        element: <ViewChairman />,
    },
    {
        path: '/chairmans/organizations',
        protected: true,
        element: <AllChairman defaultTab='organization' />,
    },
    {
        path: '/chairmans/organizations/create',
        protected: true,
        element: <CreateOrganization />,
    },
    {
        path: '/chairmans/organizations/:chairman_organization_id/view',
        protected: true,
        element: <ViewOrganization />,
    },
    {
        path: '/chairmans/organizations/:chairman_organization_id/edit',
        protected: true,
        element: <EditOrganization />,
    },
    {
        label: 'วิทยากร / ผู้เชี่ยวชาญ',
        path: '/lecturers',
        protected: true,
        icon: IconUserStar,
        element: <AllLecturer />,
    },
    {
        path: '/lecturers/create',
        protected: true,
        element: <CreateLecturer />,
    },
    {
        path: '/lecturers/:lecturer_id/edit',
        protected: true,
        element: <EditLecturer />,
    },
    {
        path: '/lecturers/:lecturer_id/view',
        protected: true,
        element: <ViewLecturer />,
    },
    {
        label: 'ความเชี่ยวชาญ',
        path: '/expertises',
        protected: true,
        element: <AllExpertise />,
        icon: IconBulb,
        privilege: 'manageExpertises',
    },
    {
        path: '/expertises/create',
        protected: true,
        element: <CreateExpertise />,
    },
    {
        path: '/expertises/:expertise_id/edit',
        protected: true,
        element: <EditExpertise />,
    },
    {
        path: '/expertises/:expertise_id/view',
        protected: true,
        element: <ViewExpertise />,
    },
    {
        label: 'ผู้ค้า',
        path: '/vendors',
        protected: true,
        icon: IconBuildingStore,
        element: <AllVendor />,
    },
    {
        path: '/vendors/types',
        protected: true,
        element: <AllVendor defaultTab='vendor_type' />,
    },
    {
        path: '/vendors/create',
        protected: true,
        element: <CreateVendor />,
    },
    {
        path: '/vendors/:vendor_id/edit',
        protected: true,
        element: <EditVendor />,
    },
    {
        path: '/vendors/:vendor_id/view',
        protected: true,
        element: <ViewVendor />,
    },
    {
        path: '/vendors/types/create',
        protected: true,
        element: <CreateVendorType />,
    },
    {
        path: '/vendors/types/:vendor_type_id/edit',
        protected: true,
        element: <EditVendorType />,
    },
    {
        path: '/vendors/types/:vendor_type_id/view',
        protected: true,
        element: <ViewVendorType />,
    },
    {
        label: 'ปีงบประมาณ',
        path: '/budgetyears',
        protected: true,
        icon: IconCalendarStats,
        element: <AllBudgetYear />,
        privilege: 'manageBudgetYears',
    },
    {
        path: '/budgetyears/create',
        protected: true,
        element: <CreateBudgetYear />,
    },
    {
        path: '/budgetyears/:budget_year/edit',
        protected: true,
        element: <EditBudgetYear />,
    },
    {
        path: '/budgetyears/:budget_year/view',
        protected: true,
        element: <ViewBudgetYear />,
    },
    {
        label: 'สรุปผลข้อมูล',
        path: '/dashboard',
        protected: true,
        icon: IconFileAnalytics,
        element: <DashboardHome />,
        privilege: 'viewDashboard',
    },
    {
        path: '/dashboard/single',
        protected: true,
        icon: IconFileAnalytics,
        element: <DashboardSingle />,
        privilege: 'viewDashboard',
    },
    {
        path: '/dashboard/mutiple',
        protected: true,
        icon: IconFileAnalytics,
        element: <DashboardMutiple />,
        privilege: 'viewDashboard',
    },
    {
        label: 'ดาวน์โหลดข้อมูล',
        path: '/download',
        protected: true,
        icon: IconFileDownload,
        element: <AllDownload />,
        privilege: 'downloadData',
    },
    {
        label: 'ผู้ใช้งานระบบ',
        path: '/users',
        protected: true,
        icon: IconUsers,
        element: <AllUser />,
        privilege: 'manageUsers',
    },
    {
        path: '/users/create',
        protected: true,
        element: <CreateUser />,
        privilege: 'manageUsers',
    },
    {
        path: '/users/:user_id/edit',
        protected: true,
        element: <EditUser />,
        privilege: 'manageUsers',
    },
    {
        path: '/users/:user_id/view',
        protected: true,
        element: <ViewUser />,
        privilege: 'manageUsers',
    },
    {
        path: '/users/positions/:user_position_id/view',
        protected: true,
        element: <ViewUserPosition />,
        privilege: 'manageUsers',
    },
    {
        path: '/users/positions/:user_position_id/edit',
        protected: true,
        element: <EditUserPosition />,
        privilege: 'manageUsers',
    },
    {
        path: '/users/positions/create',
        protected: true,
        element: <CreateUserPosition />,
        privilege: 'manageUsers',
    },

    {
        path: '*',
        protected: false,
        element: <PageNotFound404 />,
    },
]
