import api from '@config/api'
import { useQuery } from 'react-query'
import {
    ILecturerID,
    IRequestCreateLecturer,
    IRequestGetAllLecturers,
    IRequestUpdateLecturer,
    IResponseGetAllLecturers,
} from '@interfaces/api/ILecturer'
import { useQueryConfig } from './useQueryConfig'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { IResponseGetLecturerByID } from '../interfaces/api/ILecturer'
interface IUseCreateLecturer extends IFormMutationHandlerProps<IRequestCreateLecturer> {}

interface IUseUpdateLecturer extends ILecturerID, IFormMutationHandlerProps<IRequestUpdateLecturer> {}

interface IUseDeleteLecturer extends ILecturerID, IFormMutationHandlerProps<ILecturerID> {}

const Lecturer = {
    useGetAllLecturers: ({
        limit = 100,
        orderby = 'lecturer_id',
        order = 'desc',
        page = 1,
        search = '',
        has_enabled,
        status = '',
        checkowner = 0,
    }: IRequestGetAllLecturers) =>
        useQuery(
            'getAllLecturers' + orderby,
            async () => {
                try {
                    const res = await api.get('/lecturers', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            has_enabled,
                            checkowner,
                            status,
                        },
                    })
                    return res.data.body as IResponseGetAllLecturers
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetLecturerByID: ({ lecturer_id }: ILecturerID) =>
        useQuery(
            'getagencybyid' + lecturer_id,
            async () => {
                try {
                    const res = await api.get(`/lecturers/${lecturer_id}`)
                    return res.data.body as IResponseGetLecturerByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateLecturer: ({ form, aftersuccess }: IUseCreateLecturer) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/lecturers', 'post'],
            aftersuccess,
        })
    },
    useUpdateLecturer: ({ form, lecturer_id, aftersuccess }: IUseUpdateLecturer) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/lecturers/${lecturer_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteLecturer: ({ lecturer_id, aftersuccess }: IUseDeleteLecturer) => {
        return useFormMutationHandler({
            pathWithMethod: [`/lecturers/${lecturer_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default Lecturer
