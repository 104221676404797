import CardDashboardStat from '@components/card/CardDashboardStat'
import CardDashboardStatGroup from '@components/card/CardDashboardStatGroup'
import TextChartTitle from '@components/text/TextChartTitle'
import { IclassifiedNewAndOldCustomerByYear } from '@interfaces/api/IDashboard'
import { Flex } from '@mantine/core'
import Percen from '@utils/Percen'
import PercenDiff from '@utils/PercenDiff'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'

const AgencyISOStatsGroup = ({ data }: { data: IclassifiedNewAndOldCustomerByYear }) => {
    const groupedData = Object.keys(data)
    const start_budget_year = Number(groupedData[0]) + 543
    const end_budget_year = Number(groupedData[groupedData.length - 1]) + 543

    const dataForEachYear = groupedData.map((Byear, index) => {
        const all_agency = data[Byear].newcustomer.length + data[Byear].oldcustomer.length
        const old_agency = data[Byear].oldcustomer.length
        const new_agency = data[Byear].newcustomer.length
        return {
            budget_year: Number(Byear) + 543,
            all_agency,
            old_agency,
            new_agency,
        }
    })
    const title = ` สถิติยอดจำนวนลูกค้าทั้งหมด และจำนวนลูกค้ารายเก่าและลูกค้ารายใหม่
                ประจำปีงบประมาณ ${start_budget_year} ${
        start_budget_year !== end_budget_year ? ' - ' + end_budget_year : ''
    } `
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            {/* {start_budget_year !== end_budget_year && (
                <CardDashboardStatGroup>
                    <CardDashboardStat w={200} unit='หน่วยงาน' label='จำนวนหน่วยงานทั้งหมด' value={all_agency} />
                </CardDashboardStatGroup>
            )} */}

            <Flex direction='column'>
                {dataForEachYear.map((p, index) => (
                    <CardDashboardStatGroup key={index}>
                        <CardDashboardStat w={120} label={index === 0 ? 'ปีงบประมาณ' : ''} value={p.budget_year} />
                        <CardDashboardStat
                            w={180}
                            diff={
                                index === 0
                                    ? undefined
                                    : PercenDiff(dataForEachYear[index - 1].all_agency, p.all_agency)
                            }
                            unit='หน่วยงาน'
                            label={index === 0 ? 'จำนวนลูกค้าทั้งหมด' : ''}
                            value={p.all_agency}
                        />
                        <CardDashboardStat
                            w={330}
                            unit='หน่วยงาน'
                            label={index === 0 ? 'จำนวนลูกค้ารายเก่า' : ''}
                            value={`${p.old_agency} (${Percen(p.old_agency, p.all_agency)}%)`}
                        />
                        <CardDashboardStat
                            w={330}
                            unit='หน่วยงาน'
                            label={index === 0 ? 'จำนวนลูกค้ารายใหม่' : ''}
                            value={`${p.new_agency} (${Percen(p.new_agency, p.all_agency)}%)`}
                        />
                    </CardDashboardStatGroup>
                ))}
            </Flex>
        </ChartFlexSection>
    )
}

export default AgencyISOStatsGroup

// <ChartFlexSection>
// <TextChartTitle>
//     สถิติยอดจำนวนลูกค้าทั้งหมด
//     และจำนวนลูกค้ารายเก่าและลูกค้ารายใหม่
//     ประจำปีงบประมาณ {budget_year}
// </TextChartTitle>
// <CardDashboardStatGroup>
//     <CardDashboardStat
//         w={150}
//         label='ปีงบประมาณ'
//         value={budget_year}
//     />

//     <CardDashboardStat
//         w={200}
//         unit='หน่วยงาน'
//         label='จำนวนลูกค้าทั้งหมด'
//         value={allAgency}
//     />
//     <CardDashboardStat
//         w={200}
//         unit='หน่วยงาน'
//         label='จำนวนลูกค้ารายเก่า'
//         value={`${oldAgency} (${Percen(
//             oldAgency,
//             allAgency
//         )}%)`}
//     />
//     <CardDashboardStat
//         w={200}
//         unit='หน่วยงาน'
//         label='จำนวนลูกค้ารายใหม่'
//         value={`${newAgency} (${Percen(
//             newAgency,
//             allAgency
//         )}%)`}
//     />
// </CardDashboardStatGroup>
// </ChartFlexSection>
