import Project from '@api/Project'
import { Flex } from '@mantine/core'
import { useEffect, useState } from 'react'
import TablePagination from '@components/table/template/TablePagination'
import { IResponseAllProjectsRowData } from '@interfaces/api/IProject'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import InputProjectProgressStatus from '@components/form/input/InputProjectProgressStatus'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import TableDownloadButton from '@components/button/TableDownloadButton'
import exportTableToExcel from '@utils/exportTableToExcel'
import mapProjectTable from '@utils/excel_map_functions/mapProjectTable'
import TableSearchFilter from '@components/table/filter/TableSearchFilter'
import AllProjectTableRow from '@components/table/database/project/AllProjectTableRow'
import TableCheckOwnerFilter from '@components/table/filter/TableCheckOwnerFilter'
import TableBudgetYearFilter from '@components/table/filter/TableBudgetYearFilter'
import InputAgency from '@components/form/input/InputAgency'
import InputChairman from '@components/form/input/InputChairman'
import InputCoordinator from '@components/form/input/InputCoordinator'
import InputLecturer from '@components/form/input/InputLecturer'
import InputVendor from '@components/form/input/InputVendor'
import InputProjectType from '@components/form/input/InputProjectType'

const TableAllProject = ({ defaultCheckOwner = 0 }: { defaultCheckOwner?: 0 | 1 }) => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] = useState<keyof IResponseAllProjectsRowData>('project_code')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [budget_year, setbudget_year] = useState('')
    const [project_progress_status_id, setproject_progress_status_id] = useState('')
    const [agency_id, setagency_id] = useState('')
    const [chairman_id, setchairman_id] = useState('')
    const [coordinator_id, setcoordinator_id] = useState('')
    const [lecturer_id, setlecturer_id] = useState('')
    const [vendor_id, setvendor_id] = useState('')
    const [project_type_id, setproject_type_id] = useState('')
    const [checkowner, setcheckowner] = useState<number>(defaultCheckOwner)
    const projects = Project.useGetAllProjects({
        limit,
        orderby,
        order,
        page,
        search,
        budget_year,
        project_progress_status_id,
        checkowner,
        chairman_id,
        coordinator_id,
        agency_id,
        vendor_id,
        lecturer_id,
        project_type_id,
    })

    useEffect(() => {
        projects.refetch()
        // eslint-disable-next-line
    }, [
        budget_year,
        page,
        limit,
        order,
        orderby,
        project_progress_status_id,
        checkowner,
        agency_id,
        chairman_id,
        coordinator_id,
        vendor_id,
        lecturer_id,
        project_type_id,
    ])

    const thead: ITHeadMap<IResponseAllProjectsRowData>[] = [
        {
            sortkey: 'project_no',
            label: 'รหัส',
            width: '7%',
        },
        {
            sortkey: 'project_name',
            label: 'ชื่อโครงการ',
            width: '30%',
        },
        {
            sortkey: 'project_budget',
            label: 'งบประมาณ',
            width: '13%',
        },
        {
            label: 'วิทยากร',
            width: '5%',
        },
        {
            label: 'ผู้ค้า',
            width: '5%',
        },
        {
            sortkey: 'project_evaluation_result',
            label: 'ผลประเมิน',
            width: '10%',
        },
        {
            sortkey: 'project_progress',
            label: 'สถานะ',
            width: '18%',
        },
        {
            label: 'Action',
            width: '10%',
        },
    ]

    return (
        <>
            <Flex gap='md' direction={{ xs: 'column', lg: 'row' }}>
                <TableSearchFilter
                    page={page}
                    setpage={setpage}
                    setsearch={setsearch}
                    search={search}
                    refresh={projects.refetch}
                />
                {!defaultCheckOwner ? <TableCheckOwnerFilter {...{ setcheckowner, checkowner, setpage }} /> : <></>}
                <InputProjectProgressStatus
                    miw='180px'
                    value={String(project_progress_status_id)}
                    onChange={(value) => {
                        if (value !== String(project_progress_status_id)) {
                            setproject_progress_status_id((prev) => String(value))
                            setpage(1)
                        }
                    }}
                />
                <TableBudgetYearFilter {...{ setbudget_year, setpage, budget_year }} />
                <TableDownloadButton onClick={() => exportTableToExcel(projects.data?.lists!, mapProjectTable)} />
                <TableAddNewButton component={Link} to='/projects/create' label='เพิ่มโครงการ' />
            </Flex>
            <Flex mt='sm' gap='md' direction={{ xs: 'column', lg: 'row' }}>
                <InputAgency
                    w='100%'
                    value={String(agency_id)}
                    onChange={(value) => {
                        setagency_id(String(Number(value)))
                        setpage(1)
                    }}
                />
                <InputChairman
                    clearable
                    w='100%'
                    value={String(chairman_id)}
                    onChange={(value) => {
                        setchairman_id(String(Number(value)))
                        setpage(1)
                    }}
                />
                <InputCoordinator
                    clearable
                    w='100%'
                    value={String(coordinator_id)}
                    onChange={(value) => {
                        setcoordinator_id(String(Number(value)))
                        setpage(1)
                    }}
                />
                <InputLecturer
                    clearable
                    w='100%'
                    value={String(lecturer_id)}
                    onChange={(value) => {
                        setlecturer_id(String(Number(value)))
                        setpage(1)
                    }}
                />
                <InputVendor
                    clearable
                    w='100%'
                    value={String(vendor_id)}
                    onChange={(value) => {
                        setvendor_id(String(Number(value)))
                        setpage(1)
                    }}
                />
                <InputProjectType
                    clearable
                    w='100%'
                    value={String(project_type_id)}
                    onChange={(value) => {
                        setproject_type_id(String(Number(value)))
                        setpage(1)
                    }}
                />
            </Flex>
            <TableTemplate<IResponseAllProjectsRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={projects?.data?.lists}
            >
                {projects?.data?.lists.map((value) => (
                    <AllProjectTableRow key={value.project_id} data={value} refreshTable={projects.refetch} />
                ))}
            </TableTemplate>
            {projects.data?.lists.length ? (
                <TablePagination
                    limit={projects.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={projects.data.totalPages}
                    totalRows={projects.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableAllProject
