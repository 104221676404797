import React from 'react'
import TableListChart from './TableListChart'
import { IclassifiedNewAndOldCustomerByYearRowData } from '@interfaces/api/IDashboard'

interface IAgencyTableListISOChart {
    title: string
    data: IclassifiedNewAndOldCustomerByYearRowData[]
}
const AgencyTableListISOChart = ({ title, data }: IAgencyTableListISOChart) => {
    return (
        <TableListChart
            title={title}
            th={[
                { label: 'ลำดับ', width: '5%' },
                {
                    label: 'หน่วยงาน',
                    width: '40%',
                },
                {
                    label: 'ประเภท',
                    width: '15%',
                },
                {
                    label: 'เป็นลูกค้าครั้งแรกเมื่อปีงบประมาณ',
                    width: '15%',
                },
                {
                    label: 'เป็นลูกค้าล่าสุดเมื่อปีงบประมาณ',
                    width: '15%',
                },
            ]}
        >
            {data.map((p, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{p.agency_name}</td>
                        <td>{p.agency_type_name}</td>
                        <td>{p.on_boarding_year + 543}</td>
                        <td>
                            {typeof p.latest_active_year === 'string'
                                ? p.latest_active_year
                                : p.latest_active_year + 543}
                        </td>
                    </tr>
                )
            })}
        </TableListChart>
    )
}

export default AgencyTableListISOChart
