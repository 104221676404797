import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import _ from 'lodash'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import { ColumnChartOptions } from './setting'
interface IProjectEvaluationColumnChart {
    title: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

const ProjectEvaluationColumnChart = ({ title, data }: IProjectEvaluationColumnChart) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const prepareDatawithAnnotation = Object.keys(groupedData).map((Byear) => {
        const dataByYear = groupedData[Byear].filter((p) => p.project_evaluation_result)
        const DataE1 = _.meanBy(dataByYear, (p) => Number(p.project_evaluation_quality_result))
        const DataE2 = _.meanBy(dataByYear, (p) => Number(p.project_evaluation_process_result))
        const DataE3 = _.meanBy(dataByYear, (p) => Number(p.project_evaluation_personnel_result))
        const DataE4 = _.meanBy(dataByYear, (p) => Number(p.project_evaluation_facility_result))
        const DataEAll = _.meanBy(dataByYear, (p) => Number(p.project_evaluation_result))
        console.log(DataE1)
        return [
            `ปีงบประมาณ ${Number(Byear) + 543} \n`,
            DataE1,
            DataE1.toFixed(2),
            DataE2,
            DataE2.toFixed(2),
            DataE3,
            DataE3.toFixed(2),
            DataE4,
            DataE4.toFixed(2),
            DataEAll,
            DataEAll.toFixed(2),
        ]
    })

    const columnChartData = [
        [
            'ปีงบประมาณ',
            'ด้านคุณภาพการให้บริการ',
            { role: 'annotation' },
            'ด้านกระบวนการ ขั้นตอนการให้บริการ ',
            { role: 'annotation' },
            'ด้านเจ้าหน้าที่ผู้ให้บริการ',
            { role: 'annotation' },
            'ด้านสิ่งอำนวยความสะดวก',
            { role: 'annotation' },
            'ผลประเมินโดยรวม',
            { role: 'annotation' },
        ],
        ...prepareDatawithAnnotation,
    ]
    const options = ColumnChartOptions(
        'รายละเอียดผลประเมินความพึงพอใจโครงการ',
        'ค่าเฉลี่ยผลการประเมิลความพึงพอใจ 4 ด้าน และผลรวม'
    )

    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={columnChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default ProjectEvaluationColumnChart
