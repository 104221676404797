import TextChartTitle from '@components/text/TextChartTitle'
import {IResponseGetProjectDataForDashboardRowData} from '@interfaces/api/IDashboard'
import ShortMoney from '@utils/MoneyShort'
import _ from 'lodash'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import {ColumnChartOptions} from './setting'
import money from "@utils/Money";
import {Table} from "@mantine/core";

interface IProjectBudgetByQuarterColumnChart {
    title: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

type dataTable = [number, [string, number], [string, number], [string, number], [string, number]][]

const ProjectBudgetByQuarterColumnChart = ({title, data}: IProjectBudgetByQuarterColumnChart) => {
    const dataTable: dataTable = []
    const groupedData = _.groupBy(data, 'project_budget_year')
    const prepareDatawithAnnotation = Object.keys(groupedData).map((Byear) => {
        const DataByQuarter = _.groupBy(groupedData[Byear], (p) => p.quarter)
        const DataQ1 = _.sumBy(DataByQuarter['Q1'], (p) =>
            Number(p.project_budget)
        )
        const DataQ2 = _.sumBy(DataByQuarter['Q2'], (p) =>
            Number(p.project_budget)
        )
        const DataQ3 = _.sumBy(DataByQuarter['Q3'], (p) =>
            Number(p.project_budget)
        )
        const DataQ4 = _.sumBy(DataByQuarter['Q4'], (p) =>
            Number(p.project_budget)
        )

        dataTable.push([Number(Byear),
            [money(DataQ1), DataByQuarter['Q1']?.length],
            [money(DataQ2), DataByQuarter['Q2']?.length],
            [money(DataQ3), DataByQuarter['Q3']?.length],
            [money(DataQ4), DataByQuarter['Q4']?.length],
        ])


        return [
            `ปีงบประมาณ ${Number(Byear) + 543} \n (Q1-Q4)`,
            DataQ1,
            ShortMoney(DataQ1),
            DataQ2,
            ShortMoney(DataQ2),
            DataQ3,
            ShortMoney(DataQ3),
            DataQ4,
            ShortMoney(DataQ4),
        ]
    })

    const rows = dataTable.map((element) => (
        <tr key={element[0]}>
            <td style={{textAlign: 'center'}}>{element[0] + 543}</td>
            <td style={{textAlign: 'center'}}>{element[1][1] ?? 0}</td>
            <td>{element[1][0] ?? 0}</td>
            <td style={{textAlign: 'center'}}>{element[2][1] ?? 0}</td>
            <td>{element[2][0] ?? 0}</td>
            <td style={{textAlign: 'center'}}>{element[3][1] ?? 0}</td>
            <td>{element[3][0] ?? 0}</td>
            <td style={{textAlign: 'center'}}>{element[4][1] ?? 0}</td>
            <td>{element[4][0] ?? 0}</td>
        </tr>
    ));

    const columnChartData = [
        [
            'ปีงบประมาณ',
            'ไตรมาสที่ 1 (Q1)',
            {role: 'annotation'},
            'ไตรมาสที่ 2 (Q2)',
            {role: 'annotation'},
            'ไตรมาสที่ 3 (Q3)',
            {role: 'annotation'},
            'ไตรมาสที่ 4 (Q4)',
            {role: 'annotation'},
        ],
        ...prepareDatawithAnnotation,
    ]
    const options = ColumnChartOptions(
        'มูลค่างบประมาณโครงการ',
        'ไตรมาสปีงบประมาณ (Quarter)'
    )


    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <div>
                <Table width='fit' style={{width: "auto", tableLayout: "auto"}} withBorder withColumnBorders striped>
                    <thead>
                    <tr>
                        <th color='black' style={{textAlign: 'center'}} align='center' rowSpan={2}>ปีงบประมาณ</th>
                        <th style={{textAlign: 'center'}} colSpan={2}>Q1</th>
                        <th style={{textAlign: 'center'}} colSpan={2}>Q2</th>
                        <th style={{textAlign: 'center'}} colSpan={2}>Q3</th>
                        <th style={{textAlign: 'center'}} colSpan={2}>Q4</th>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'center'}}>จำนวนโครงการ</th>
                        <th style={{textAlign: 'center'}}>มูลค่า</th>
                        <th style={{textAlign: 'center'}}>จำนวนโครงการ</th>
                        <th style={{textAlign: 'center'}}>มูลค่า</th>
                        <th style={{textAlign: 'center'}}>จำนวนโครงการ</th>
                        <th style={{textAlign: 'center'}}>มูลค่า</th>
                        <th style={{textAlign: 'center'}}>จำนวนโครงการ</th>
                        <th style={{textAlign: 'center'}}>มูลค่า</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={columnChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default ProjectBudgetByQuarterColumnChart
