import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Flex, Rating, Table } from '@mantine/core'
import Money from '@utils/Money'
import ChartFlexSection from './ChartFlexSection'
interface IProjectRankingEvaluationChart {
    title: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

const ProjectRankingEvaluationChart = ({ title, data }: IProjectRankingEvaluationChart) => {
    data.sort((a, b) => Number(b.project_evaluation_result) - Number(a.project_evaluation_result))
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Table pos={'relative'}>
                <thead>
                    <tr>
                        <TableHeaderColumn label={'อันดับ'} width={'3%'} />
                        <TableHeaderColumn label={'รหัสโครงการ'} width={'5%'} />
                        <TableHeaderColumn label={'ชื่อโครงการ'} width={'40%'} />
                        <TableHeaderColumn label={'คุณภาพการให้บริการ'} width={'10%'} />
                        <TableHeaderColumn label={'ขั้นตอนการให้บริการ'} width={'10%'} />
                        <TableHeaderColumn label={'เจ้าหน้าที่ผู้ให้บริการ'} width={'10%'} />
                        <TableHeaderColumn label={'สิ่งอำนวยความสะดวก'} width={'10%'} />
                        <TableHeaderColumn label={'ผลการประเมินความพึงพอใจ'} width={'12%'} />
                    </tr>
                </thead>
                <tbody>
                    {data.map((p, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{p.project_code}</td>
                            <td>{p.project_name}</td>
                            <td>
                                <RatingResult evaluation_result={p.project_evaluation_quality_result} />
                            </td>
                            <td>
                                <RatingResult evaluation_result={p.project_evaluation_process_result} />
                            </td>
                            <td>
                                <RatingResult evaluation_result={p.project_evaluation_personnel_result} />
                            </td>
                            <td>
                                <RatingResult evaluation_result={p.project_evaluation_facility_result} />
                            </td>
                            <td>
                                <RatingResult evaluation_result={p.project_evaluation_result} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ChartFlexSection>
    )
}

const RatingResult = ({ evaluation_result }: { evaluation_result: string | number | null }) => {
    const e_result = Number(evaluation_result)
    if (e_result) {
        return (
            <Flex gap='xs'>
                {Money(Number(evaluation_result))}
                <Rating fractions={5} value={Number(evaluation_result)} readOnly />
            </Flex>
        )
    } else {
        return <>ยังไม่ประเมิน</>
    }
}

export default ProjectRankingEvaluationChart
