import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, NumberInput, Paper, Textarea, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateLecturer } from '@interfaces/api/ILecturer'
import Lecturer from '@api/Lecturer'
import InputExpertises from '../input/InputExpertises'

const FormCreateLecturer = ({ aftersuccess }: { aftersuccess?: () => any }) => {
    const form = useForm<IRequestCreateLecturer>({
        initialValues: {
            lecturer_fullname: '',
            lecturer_organization: '',
            lecturer_email: '',
            lecturer_phone: '',
            lecturer_detail: '',
            lecturer_expertises: [],
            sequence_no: '',
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Lecturer.useCreateLecturer({
        form,
        aftersuccess: () => navigate('/lecturers'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <NumberInput
                        type='number'
                        label='ลำดับ'
                        placeholder='ลำดับ'
                        {...form.getInputProps('sequence_no')}
                    />
                    <TextInput
                        withAsterisk
                        label='ชื่อวิทยากร'
                        placeholder='ชื่อวิทยากร'
                        {...form.getInputProps('lecturer_fullname')}
                    />
                    <TextInput
                        label='สังกัด/หน่วยงาน'
                        placeholder='สังกัด/หน่วยงาน'
                        {...form.getInputProps('lecturer_organization')}
                    />
                    <TextInput label='อีเมล' placeholder='อีเมล' {...form.getInputProps('lecturer_email')} />
                    <TextInput label='เบอร์โทร' placeholder='เบอร์โทร' {...form.getInputProps('lecturer_phone')} />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับวิทยากร'
                        placeholder='เกี่ยวกับวิทยากร'
                        {...form.getInputProps('lecturer_detail')}
                    />

                    <InputExpertises {...form.getInputProps('lecturer_expertises')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button component={Link} to='/lecturers' variant='default' w='fit-content'>
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างวิทยากร
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateLecturer
