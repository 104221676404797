import api, { installHandleRefreshAPI } from '@config/api'
import { v4 as uuidv4 } from 'uuid'
import { IResponseGetProfile, IResponseLogin } from '@interfaces/api/IAuth'
import { IAuth } from '@interfaces/IAuth'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import React, { createContext, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { cookie_config } from '@config/cookie_setting'
import { useNavigate } from 'react-router-dom'

// Define the AuthContext type
interface AuthContextType {
    auth: IAuth | null
    login: (data: IResponseLogin) => void
    logout: () => void
    isAuthenticated: () => boolean
    isLoading: boolean
}

// Create the AuthContext with initial values
export const AuthContext = createContext<AuthContextType>({
    auth: null,
    login: () => {},
    logout: () => {},
    isAuthenticated: () => false,
    isLoading: false,
})

// Create the AuthProvider component
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate()
    const [auth, setAuth] = useState<IAuth | null>(null)
    const [cookies, setCookie, removeCookie] = useCookies(['session'])
    const [isLoading, setIsLoading] = useState(true)

    const query = async () => {
        try {
            if (auth === null) {
                await api.post('/auth/refreshtoken')
                setCookie('session', uuidv4(), cookie_config)
            }
            const res = await api.get('/auth/profile')
            const data = res.data.body as IResponseGetProfile
            setAuth({
                user_id: data.user_id,
                privilege_id: data.privilege_id,
                user_fullname: data.user_fullname,
            })
        } catch (err: any) {
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                autoClose: 5000,
                message: 'กรุณาเข้าสู่ระบบใหม่',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
            logout()
        }
        setIsLoading(false)
    }
    useEffect(() => {
        let ExpiredUUID = ''

        if (cookies.session) {
            if (isLoading === true) {
                query()
            }
        } else {
            setIsLoading(false)
            logout()
        }

        installHandleRefreshAPI(() => {
            if (ExpiredUUID === '') {
                ExpiredUUID = uuidv4()
                removeCookie('session')
                setAuth(null)
                navigate('/login')
                notifications.show({
                    key: 'session-expired',
                    title: 'Session Expired',
                    autoClose: 5000,
                    message: 'กรุณาเข้าสู่ระบบใหม่',
                    color: 'red',
                    icon: <IconExclamationCircle size='1rem' />,
                    onClose: () => {
                        ExpiredUUID = ''
                    },
                })
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Simulate a login action (you can replace this with your authentication logic)
    const login = (data: IResponseLogin): void => {
        // removeCookie('token')
        setCookie('session', uuidv4(), cookie_config)
        api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`

        setAuth(data)
    }

    // Simulate a logout action
    const logout = async () => {
        removeCookie('session')
        await api.get('/auth/logout')
        setAuth(null)
    }

    // Check if the user is authenticated (logged in)
    const isAuthenticated = () => {
        return auth?.user_id ? true : false
    }

    return (
        <AuthContext.Provider value={{ auth, login, isLoading, logout, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
