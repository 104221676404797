import TextChartTitle from '@components/text/TextChartTitle'
import {
    IclassifiedNewAndOldCustomerByYear,
    IResponseGetProjectDataForDashboardRowData,
} from '@interfaces/api/IDashboard'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import { ColumnChartOptions } from './setting'

interface IAgenciesAmountISOColumnChart {
    title: string
    typeName: string
    valueName: string
    data: IclassifiedNewAndOldCustomerByYear
    keySort: keyof IResponseGetProjectDataForDashboardRowData
    keyValue: keyof IResponseGetProjectDataForDashboardRowData
    keyType: keyof IResponseGetProjectDataForDashboardRowData
}

const AgenciesAmountISOColumnChart = ({
    title,
    typeName,
    valueName,
    data,
    keyValue,
    keyType,
    keySort,
}: IAgenciesAmountISOColumnChart) => {
    const groupedData = Object.keys(data)
    const prepareDatawithAnnotation = groupedData.map((Byear, index) => {
        const old_agency = data[Byear].oldcustomer.length
        const new_agency = data[Byear].newcustomer.length

        return [`ปีงบประมาณ ${Number(Byear) + 543}`, old_agency, old_agency, new_agency, new_agency]
    })
    const columnChartData = [
        ['ปีงบประมาณ', 'ลูกค้าเก่า', { role: 'annotation' }, 'ลูกค้าใหม่', { role: 'annotation' }],
        ...prepareDatawithAnnotation,
    ]

    const options = ColumnChartOptions(valueName, typeName)
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={columnChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default AgenciesAmountISOColumnChart
