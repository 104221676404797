import {
    IResponseDownloadProjectDataRowData,
    IResponseDownloadProjectLecturerDataRowData,
    IResponseDownloadProjectPeriodDataRowData,
    IResponseDownloadProjectVendorDataRowData,
} from '@interfaces/api/IDownload'
import * as XLSX from 'xlsx'
import excelFormatCellAuthFitWidth from './excelFormatCellAuthFitWidth'

interface Data {
    projects: IResponseDownloadProjectDataRowData[]
    periods: IResponseDownloadProjectPeriodDataRowData[]
    lecturers: IResponseDownloadProjectLecturerDataRowData[]
    vendors: IResponseDownloadProjectVendorDataRowData[]
}

function exportDownloadProjectDataToExcel(data: Data) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new()
    const prepareProjectData = data.projects.map((p) => ({
        ไอดีโครงการ: p.project_id,
        ลำดับโครงการ: p.project_no,
        รหัสโครงการ: p.project_code,
        ปีงบประมาณ: p.budget_year + 543,
        เลขที่สัญญา: p.project_promise_no,
        ชื่อโครงการ: p.project_name,
        ไอดีประเภทกิจกรรมบริการวิชาการ: p.project_type_id,
        ชื่อประเภทกิจกรรมบริการวิชาการ: p.project_type_name,
        วันที่เริ่มโครงการ: p.project_start_date,
        วันที่สิ้นสุดโครงการ: p.project_end_date,
        ระยะเวลา: p.project_duration,
        งบประมาณ: Number(p.project_budget),
        จำนวนงวด: p.project_period_amount,
        วันที่อธิการบดีอนุมัติโครงการ: p.rector_project_approval_date,
        วันที่ส่งรายงานการเงิน: p.financial_report_submission_date,
        'วันที่แจ้งอธิการบดี เรื่อง ลงนามสัญญาจ้าง': p.rector_signing_employment_contract_request_date,
        มีข้อตกลงประธานโครงการ: p.has_project_chair_agreement,
        'มีหนังสือแจ้งคณบดี เรื่อง ประธานโครงการ': p.has_letter_to_dean_about_chairman,
        งบประมาณเดิม: p.old_project_budget,
        'วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอปรับงบประมาณ': p.rector_budget_adjustment_request_date,
        'วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอปรับรายระเอียดงบประมาณ': p.rector_budget_detail_adjustment_request_date,
        ชื่อโครงการเดิม: p.old_project_name,
        'วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง เปลี่ยนแปลงชื่อโครงการ': p.rector_project_name_change_request_date,
        ระยะเวลาโครงการเดิม: p.old_project_duration,
        'วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอเปลี่ยนระยะเวลาโครงการ': p.rector_project_period_extension_request_date,
        หมายเหตุ: p.project_other_note,
        มีการจัดทำพัสดุหรือไม่: p.has_parcel,
        วันที่ส่งการเงิน: p.submit_to_finance_department_date,
        วันที่ส่งคืนแก้ไข: p.submit_to_fixed_date,
        วันที่ผู้จัดการลงนาม: p.manager_sign_date,
        วันที่ผู้อำนวยการลงนาม: p.director_sign_date,
        วันที่บัญชีจัดเก็บ: p.accounting_department_collect_date,
        ไอดีประธานโครงการ: p.chairman_id,
        ชื่อประธานโครงการ: p.chairman_fullname,
        ไอดีผู้ประสานงาน: p.coordinator_id,
        ชื่อผู้ประสานงาน: p.coordinator_fullname,
        ไอดีหน่วยงาน: p.agency_id,
        ชื่อหน่วยงาน: p.agency_name,
        ไอดีประเภทหน่วยงาน: p.agency_type_id,
        ชื่อประเภทหน่วยงาน: p.agency_type_name,
        ไอดีสถานะโครงการ: p.project_progress_status_id,
        ชื่อสถานะความคืบหน้าโครงการ: p.project_progress_status_name,
        สีสถานะความคืบหน้าโครงการ: p.project_progress_status_color,
        ค่าความคืบหน้าโครงการ: p.project_progress,
        คะแนนความพึงพอใจด้านคุณภาพการให้บริการ: Number(p.project_evaluation_quality_result),
        คะแนนความพึงพอใจด้านกระบวนการขั้นตอนการให้บริการ: Number(p.project_evaluation_process_result),
        คะแนนความพึงพอใจด้านเจ้าหน้าที่ผู้ให้บริการ: Number(p.project_evaluation_personnel_result),
        คะแนนความพึงพอใจด้านสิ่งอำนวยความสะดวก: Number(p.project_evaluation_facility_result),
        คะแนนความพึงพอใจโดยรวม: Number(p.project_evaluation_result),
        ประเมินความพึงพอใจโดยผู้ใช้งานไอดี: p.evaluation_by_user_id,
        ประเมินความพึงพอใจโดยผู้ใช้งานชื่อ: p.evaluation_by_user_fullname,
        ประเมินความพึงพอใจเมื่อ: p.evaluation_at,
        สร้างเมื่อ: p.created_at,
        สร้างโดยผู้ใช้งานไอดี: p.created_by_user_id,
        สร้างโดยผู้ใช้งานชื่อ: p.created_by_user_fullname,
        แก้ไขเมื่อ: p.updated_at,
        แก้ไขโดยผู้ใช้งานไอดี: p.updated_by_user_id,
        แก้ไขโดยผู้ใช้งานชื่อ: p.updated_by_user_fullname,
    }))

    const prepareProjectLecturerData = data.lecturers.map((p) => ({
        ไอดีข้อมูลวิทยากรโครงการ: p.project_lecturer_id,
        ไอดีโครงการ: p.project_id,
        ไอดีวิทยากร: p.lecturer_id,
        ชื่อวิทยากร: p.lecturer_fullname,
        อีเมลวิทยากร: p.lecturer_email,
        เบอร์โทร: p.lecturer_phone,
        เกี่ยวกับวิทยากร: p.lecturer_detail,
        ความเชี่ยวชาญ: p.lecturer_expertises_name,
        คะแนนความพึงพอใจ: p.lecturer_evaluation_result,
        ประเมินความพึงพอใจโดยผู้ใช้งานไอดี: p.evaluation_by_user_id,
        ประเมินความพึงพอใจโดยผู้ใช้งานชื่อ: p.evaluation_by_user_fullname,
        ประเมินความพึงพอใจเมื่อ: p.evaluation_at,
        สร้างเมื่อ: p.created_at,
        สร้างโดยผู้ใช้งานไอดี: p.created_by_user_id,
        สร้างโดยผู้ใช้งานชื่อ: p.created_by_user_fullname,
        แก้ไขเมื่อ: p.updated_at,
        แก้ไขโดยผู้ใช้งานไอดี: p.updated_by_user_id,
        แก้ไขโดยผู้ใช้งานชื่อ: p.updated_by_user_fullname,
    }))

    const prepareProjectVendorData = data.vendors.map((p) => ({
        ไอดีข้อมูลผู้ค้าโครงการ: p.project_vendor_id,
        ไอดีโครงการ: p.project_id,
        ไอดีผู้ค้า: p.vendor_id,
        ชื่อผู้ค้า: p.vendor_name,
        อีเมลผู้ค้า: p.vendor_email,
        เบอร์โทร: p.vendor_phone,
        ผู้ค้าเมื่อปีงบประมาณ: p.budget_year,
        เกี่ยวกับผู้ค้า: p.vendor_detail,
        ความเชี่ยวชาญ: p.vendor_types_name,
        ผลประเมินการประสานงานก่อนการซื้อขายหรือบริการ: p.vendor_evaluation_factor_1,
        ผลประเมินการซื้อขายและการให้บริการระหว่างการซื้อขายหรือบริการ: p.vendor_evaluation_factor_2,
        ผลประเมินการให้บริการหลังการขายหรือบริการ: p.vendor_evaluation_factor_3,
        ผลรวมการประเมิน: p.vendor_evaluation_result,
        ผลการประเมิน: p.vendor_evaluation_grade,
        ประเมินความพึงพอใจโดยผู้ใช้งานไอดี: p.evaluation_by_user_id,
        ประเมินความพึงพอใจโดยผู้ใช้งานชื่อ: p.evaluation_by_user_fullname,
        ประเมินความพึงพอใจเมื่อ: p.evaluation_at,
        สร้างเมื่อ: p.created_at,
        สร้างโดยผู้ใช้งานไอดี: p.created_by_user_id,
        สร้างโดยผู้ใช้งานชื่อ: p.created_by_user_fullname,
        แก้ไขเมื่อ: p.updated_at,
        แก้ไขโดยผู้ใช้งานไอดี: p.updated_by_user_id,
        แก้ไขโดยผู้ใช้งานชื่อ: p.updated_by_user_fullname,
    }))

    const prepareProjectPeriodData = data.periods.map((p) => ({
        ไอดีโครงการ: p.project_id,
        งวดที่: p.period_id,
        งบประมาณ: Number(p.period_budget),
        วันที่เริ่มงวดงาน: p.period_start_date,
        วันที่สิ้นสุดงวดงาน: p.period_end_date,
        ระยะเวลา: p.period_duration,
        วันที่ส่งรายงานงวด: p.period_report_submission_date,
        วันที่ส่งหนังสือเบิกเงิน: p.disbursement_submission_date,
        วันที่หน่วยงานโอนเงินมายังศูนย์: p.disbursement_received_date,
        วันที่ประธานเบิกเงิน: p.chairman_disbursement_date,
        ไอดีสถานะงวดงาน: p.period_progress_status_id,
        ชื่อสถานะความคืบหน้างวดงาน: p.period_progress_status_name,
        สีสถานะความคืบหน้างวดงาน: p.period_progress_status_color,
        ค่าความคืบหน้างวดงาน: p.period_progress,
        สร้างเมื่อ: p.created_at,
        สร้างโดยผู้ใช้งานไอดี: p.created_by_user_id,
        สร้างโดยผู้ใช้งานชื่อ: p.created_by_user_fullname,
        แก้ไขเมื่อ: p.updated_at,
        แก้ไขโดยผู้ใช้งานไอดี: p.updated_by_user_id,
        แก้ไขโดยผู้ใช้งานชื่อ: p.updated_by_user_fullname,
    }))

    // Create worksheets
    const worksheet1 = XLSX.utils.json_to_sheet(prepareProjectData)
    const worksheet2 = XLSX.utils.json_to_sheet(prepareProjectPeriodData)
    const worksheet3 = XLSX.utils.json_to_sheet(prepareProjectLecturerData)
    const worksheet4 = XLSX.utils.json_to_sheet(prepareProjectVendorData)

    excelFormatCellAuthFitWidth(worksheet1, prepareProjectData as any)
    excelFormatCellAuthFitWidth(worksheet2, prepareProjectPeriodData as any)
    excelFormatCellAuthFitWidth(worksheet3, prepareProjectLecturerData as any)
    excelFormatCellAuthFitWidth(worksheet4, prepareProjectVendorData as any)
    // Add worksheets to the workbook with different names
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'projects')
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'periods')
    XLSX.utils.book_append_sheet(workbook, worksheet3, 'lecturers')
    XLSX.utils.book_append_sheet(workbook, worksheet4, 'vendors')

    // Generate Excel data as an array buffer
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

    // Create a blob from the array buffer
    const blob = new Blob([excelData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the blob
    const url = URL.createObjectURL(blob)

    // Create a download link and trigger the download
    const a = document.createElement('a')
    a.href = url
    a.download = 'ข้อมูลโครงการ'
    a.click()

    // Clean up by revoking the blob URL
    URL.revokeObjectURL(url)
}

export default exportDownloadProjectDataToExcel
