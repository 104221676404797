import TextChartTitle from '@components/text/TextChartTitle'
import {IResponseGetProjectDataForDashboardRowData} from '@interfaces/api/IDashboard'
import ShortMoney from '@utils/MoneyShort'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import {ColumnChartOptions} from './setting'

interface IColumnChart {
    title: string
    typeName: string
    valueName: string
    data: IResponseGetProjectDataForDashboardRowData[]
    keySort: keyof IResponseGetProjectDataForDashboardRowData
    keyValue: keyof IResponseGetProjectDataForDashboardRowData
    keyType: keyof IResponseGetProjectDataForDashboardRowData
}

const ColumnChart = ({title, typeName, valueName, data, keyValue, keyType, keySort}: IColumnChart) => {
    const cleandata = data.map((v) => ({...v, [keySort]: v[keySort] ?? 0}))
    if (cleandata.length) {
        if (typeof cleandata[0][keySort] === 'string') {
            cleandata.sort((a, b) => {
                return a[keySort]! > b[keySort]! ? 1 : b[keySort]! > a[keySort]! ? -1 : 0
            })
        }
    }

    const prepareData: Record<string, number> = cleandata.reduce((acc, item) => {
        const quarter = item[keyType] as string
        acc[quarter] = (acc[quarter] || 0) + parseFloat(String(item[keyValue]))
        return acc
    }, {} as Record<string, number>)

    const prepareDatawithAnnotation = Object.entries(prepareData).map(([key, value]) => {
        const newKey = keyType === 'project_budget_year' ? `ปีงบประมาณ ${Number(key) + 543}` : key
        return [newKey, value, ShortMoney(value)]
    })

    const columnChartData = [[typeName, valueName, {role: 'annotation'}], ...prepareDatawithAnnotation]
    const options = ColumnChartOptions(valueName, typeName)

    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}

                height={'500px'}
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={columnChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default ColumnChart
