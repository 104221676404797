import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Progress, Table } from '@mantine/core'
import Money from '@utils/Money'
import ChartFlexSection from './ChartFlexSection'
interface IProjectRankingChart {
    title: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

const ProjectRankingChart = ({ title, data }: IProjectRankingChart) => {
    data.sort((a, b) => Number(b.project_budget) - Number(a.project_budget))
    const top5data = data.slice(0, 5)
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Table pos={'relative'}>
                <thead>
                    <tr>
                        <TableHeaderColumn label={'อันดับ'} width={'5%'} />
                        <TableHeaderColumn label={'รหัสโครงการ'} width={'10%'} />
                        <TableHeaderColumn label={'ชื่อโครงการ'} width={'40%'} />
                        <TableHeaderColumn label={'งบประมาณ'} width={'10%'} />
                        <TableHeaderColumn label={''} width={'400px'} />
                    </tr>
                </thead>
                <tbody>
                    {top5data.map((p, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{p.project_code}</td>
                            <td>{p.project_name}</td>
                            <td>{Money(Number(p.project_budget))}</td>
                            <td>
                                <Progress
                                    w='100%'
                                    size='xl'
                                    sections={[
                                        {
                                            value: Number(p.project_budget) / (Number(data[0].project_budget) / 100),
                                            color: 'blue',
                                        },
                                    ]}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ChartFlexSection>
    )
}

export default ProjectRankingChart
