const titleTextStyle = {
    fontSize: 16,
    fontName: 'Noto Sans Thai', // Specify the desired font name
    fontWeight: 'bold', // Specify the desired font weight
}

const textStyle = {
    fontSize: 14,
    fontName: 'Noto Sans Thai', // Specify the desired font name
}

export const ColumnChartOptions = (valueName: string, typeName: string) => ({
    chartArea: {width: '85%', height: '75%'},
    seriesType: 'bars',
    series: {5: {type: 'line'}},
    legend: {
        textStyle,
        titleTextStyle,
        position: 'top', // Set the legend position to 'top'
    },
    vAxis: {
        format: 'short',
        title: valueName,
        minValue: 0,
        titleTextStyle,
        textStyle,
    },
    hAxis: {
        format: 'short',
        title: typeName,
        titleTextStyle,
        textStyle,
    },
    annotations: {
        textStyle,
        titleTextStyle,
        boxStyle: {
            stroke: 'none', // Remove the border
        },
        alwaysOutside: true, // Display annotations outside the chart
    },
})

export const PieChartOptions = (valueName: string, typeName: string) => ({
    chartArea: {width: '85%', height: '75%'},
    seriesType: 'bars',
    series: {100: {type: 'line'}},
    pieHole: 0.5,
    sliceVisibilityThreshold: -1,

    vAxis: {
        title: valueName,
        minValue: 0,
        titleTextStyle,
        textStyle,
    },
    hAxis: {
        title: typeName,
        titleTextStyle,
        textStyle,
    },
    legend: {
        position: 'labeled',
        titleTextStyle,
        textStyle,
    },
    annotations: {
        titleTextStyle,
        textStyle,
        boxStyle: {
            stroke: 'none', // Remove the border
        },
        alwaysOutside: true, // Display annotations outside the chart
    },
})
