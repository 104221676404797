import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, NumberInput, Paper, Textarea, TextInput } from '@mantine/core'

import { Link, useNavigate } from 'react-router-dom'

import InputStatus from '../input/InputStatus'
import { IResponseGetLecturerByID, IRequestUpdateLecturer } from '@interfaces/api/ILecturer'
import Lecturer from '@api/Lecturer'
import InputExpertises from '../input/InputExpertises'

const FormEditLecturer = ({ data, aftersuccess }: { data: IResponseGetLecturerByID; aftersuccess?: () => any }) => {
    const form = useForm<IRequestUpdateLecturer>({
        initialValues: {
            lecturer_fullname: data.lecturer_fullname ?? '',
            lecturer_organization: data.lecturer_organization ?? '',
            lecturer_email: data.lecturer_email ?? '',
            lecturer_phone: data.lecturer_phone ?? '',
            lecturer_detail: data.lecturer_detail ?? '',
            lecturer_expertises: data.lecturer_expertises_id ? String(data.lecturer_expertises_id).split(',') : [],
            sequence_no: data.sequence_no ?? '',
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Lecturer.useUpdateLecturer({
        form,
        lecturer_id: data.lecturer_id,
        aftersuccess: () => navigate('/lecturers'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <NumberInput
                        type='number'
                        label='ลำดับ'
                        placeholder='ลำดับ'
                        {...form.getInputProps('sequence_no')}
                    />
                    <TextInput
                        withAsterisk
                        label='ชื่อวิทยากร'
                        placeholder='ชื่อวิทยากร'
                        {...form.getInputProps('lecturer_fullname')}
                    />
                    <TextInput
                        label='สังกัด/หน่วยงาน'
                        placeholder='สังกัด/หน่วยงาน'
                        {...form.getInputProps('lecturer_organization')}
                    />
                    <TextInput label='อีเมล' placeholder='อีเมล' {...form.getInputProps('lecturer_email')} />
                    <TextInput label='เบอร์โทร' placeholder='เบอร์โทร' {...form.getInputProps('lecturer_phone')} />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับวิทยากร'
                        placeholder='เกี่ยวกับวิทยากร'
                        {...form.getInputProps('lecturer_detail')}
                    />
                    <InputExpertises {...form.getInputProps('lecturer_expertises')} />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button component={Link} to='/lecturers' variant='default' w='fit-content'>
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขวิทยากร
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditLecturer
