const ShortMoney = (number: number) => {
    if (number === 0) {
        return '0'
    }
    // Define an array of suffixes for different magnitudes
    const suffixes = ['', 'K', 'M', 'B', 'T']

    // Find the magnitude of the number (how many digits are there)
    const magnitude = Math.floor(Math.log10(number))

    // Determine the index of the suffix to use based on the magnitude
    const suffixIndex = Math.floor(magnitude / 3)

    // Calculate the formatted number by dividing by 1000 raised to the power of the suffixIndex
    const formattedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(2)

    // Append the suffix
    const suffix = suffixes[suffixIndex]

    return `${formattedNumber}${suffix}`
}

export default ShortMoney
